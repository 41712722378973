export const DAILY_LESSON_LOG_KINDER_PROMPT_NAME = 'dailyLessonLogKinder';
export const DAILY_LESSON_LOG_G1G12_PROMPT_NAME = 'dailyLessonLogG1G12';
export const DAILY_LESSON_LOG_MATATAG_PROMPT_NAME = 'matatagDLL';
export const dllSections = {
  objectives: {
    title: 'I. OBJECTIVES',
    definition:
      'Objectives must be met over the week and connected to the curriculum standards. To meet the objectives, necessary procedures must be followed and if needed, additional lessons, exercises and remedial activities may be done for developing content knowledge and competencies. These are assessed using Formative Assessment strategies. Valuing objectives support the learning of content and competencies and enable children to find significance and joy in learning the lessons. Weekly objectives shall be derived from the curriculum guides.',
    subContentText: [
      'A. Content Standards',
      'B. Performance Standards',
      'C. Learning Competencies / Objectives Write the LC code for each',
    ],
  },
  content: {
    title: 'II. CONTENT',
    definition:
      'Content is what the lesson is all about. It Pertains to the subject matter that the teacher aims to teach in the CG, the content can be tacked in a week or two.',
  },
  learningResources: {
    title: 'III. LEARNING RESOURCES',
    definition:
      'List the materials to be used in different days. These sources of learning sustain children’s interest in the lesson and in learning. Ensure that there is a mix of concrete and manipulative materials as well as appropriate materials. Helps in learning, enhances concept development.',
    subContentText: [
      'A. References',
      '1. Teacher’s Guide pages',
      '2. Learner’s Materials pages',
      '3. Textbook pages',
      '4. Additional Materials from Learning Resource (LR) portal',
      'B. Other Learning Resources',
    ],
  },
  procedures: {
    title: 'IV. PROCEDURES',
    definition:
      'These steps should be done across the week. Spread out the activities appropriately for total student well-being. Always be guided by characteristics of learning for the students which you can infer from formative assessment activities. Balance the learning experiences by providing students with multiple opportunities to learn new things, practice and reviewing, confirm their learning experiences, and their opportunities to show what they learned through various means and express themselves. Include time intervals for each step.',
    subContentText: [
      'A. Reviewing previous lesson or presenting the new lesson',
      'B. Establishing a purpose for the lesson',
      'C. Presenting examples/instances of the new lesson',
      'D. Discussing new concepts and practicing new skills #1',
      'E. Discussing new concepts and practicing new skills #2',
      'F. Developing mastery (Leads to Formative Assessment 3)',
      'G. Finding practical applications of concepts and skills in daily living',
      'H. Making generalizations and abstractions about the lesson',
      'I. Evaluating learning',
      'J. Additional activities for application or remediation',
    ],
  },
  remarks: {
    title: 'V. REMARKS',
  },
  reflection: {
    title: 'VI. REFLECTION',
    definition:
      "Reflect on your teaching and assess yourself as a teacher. Think about your students' progress this week. What works? What else needs to be done to help the students learn? Identify what help your instructional supervisors can provide for you so that you meet them, you can use the following questions:",
    subContentText: [
      'A. No. of learners who earned 80% in the evaluation.',
      'B. No. of learners who require additional activities for remediation who scored below 80%.',
      'C. Did the remedial lessons work? No. of learners who have caught up with the lesson.',
      'D. No. of learners who continue to require remediation.',
      'E. Which of my teaching strategies worked well? Why did these work?',
      'F. What difficulties did I encounter which my principal or supervisor can help me solve?',
      'G. What innovation or localized materials did I use/discover which I wish to share with other teachers?',
    ],
  },
};

export const procedures = [
  'Reviewing previous lesson or presenting the new lesson',
  'Establishing purpose for the lesson',
  'Presenting examples / instances of the new lesson',
  'Discussing new concepts and practicing new skills #1',
  'Discussing new concepts and practicing new skills #2',
  'Developing mastery',
  'Finding practical applications of concepts and skills in daily living',
  'Making generalizations and abstractions about the lesson',
  'Evaluating learning',
  'Additional activities for application or remediation',
];
